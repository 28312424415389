<template>
  <div
    class="full-area vertically-centered bg-image"
  >
    <div class="yet-another-wrapper">
      <vue-markdown class="html-content">{{data.fields.text}}</vue-markdown>

      <p class="small" v-html="data.fields.remarks"></p>
      <el-button
        round
        type="danger"
        @click="ctaAction()"
        class="align-right"
        v-if="data.fields.callToAction"
      >{{data.fields.callToAction}}</el-button>
    </div>

    <div class="qr-camera-wrapper">
      <qrcode-stream @decode="onDecode" v-if="!form.decoded"></qrcode-stream>
      <transition name="fade">
        <div class="success-message delay-entry" v-if="form.decoded">
          ¡Listo!
        </div>
      </transition>
    </div>

    <div
      class="wrapper qr-input-field"
    >
      <el-input
        size="large"
        type="textarea"
        :disabled="form.decoded"
        v-model="qrInput"
      ></el-input>
      <el-button
        type="danger"
        @click="submit()"
        class="submit-button"
        :disabled="disableSubmitButton || form.decoded"
      >Enviar</el-button>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'QRReader',

  transition: 'page',

  props: [ 'data' ],

  components: {
    VueMarkdown,
    QrcodeStream
  },

  data () {
    return {
      qrInput: null,
      disableSubmitButton: true,
      nickname: null,
      form: {
        decoded: false,
        question: this.data.fields.name,
        id: this.data.sys.id,
        selected: null,
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      }
    }
  },

  computed: {
    routeName () {
      return this.$route.name
    }
  },

  methods: {
    onDecode ($event) {
      this.qrInput = $event
      this.disableSubmitButton = false
      this.form.decoded = true
      setTimeout(() => {
        this.form.selected = [this.qrInput]
      }, 2000)
    },
    submit () {
      if (this.validInput(this.qrInput)) {
        this.form.selected = [this.qrInput]
      }
    },
    next () {
      this.arrows = false
      this.form.timestamps.finish = new Date()
      this.$emit('next', this.form)
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value && value.length >= 5) {
        this.form.timestamps.valid = new Date()
        this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal[0])) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    },
    'qrInput': function (newVal, oldVal) {
      if (this.validInput(newVal)) {
        this.disableSubmitButton = false
      } else {
        this.disableSubmitButton = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.qr-camera-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-height: 40%;
  overflow: hidden;
}
.success-message {
  font-size: 4em;
  color: $color-emphasis;
  span {
    display: block;
    font-size: 0.5em;
    color: $text-main;
  }
}
.yet-another-wrapper {
  margin-top: 2em;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

</style>
<style lang="scss">
.qr-input-field {
  .el-textarea {
    font-size: 2em;
    font-family: monospace;
    margin-bottom: 0.5em;
  }
  .el-textarea__inner:focus {
    border-color: rgba($gray-medium, 0.4);
  }
  .el-input__inner {
    border: 1px solid $color-emphasis;
    text-align: center !important;
    font-size: 2em;
    padding: 1em;
    font-family: monospace;
  }
  .el-input__inner:focus {
    border-color: rgba($gray-medium, 0.4);
  }
}

</style>
